import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import {
  Button,
  Checkbox,
  IconButton,
  TextField,
  ToggleButton,
  Tooltip,
  styled,
} from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { useState } from 'react';
import { BiSolidCoupon } from 'react-icons/bi';
import { FaRegShareSquare } from 'react-icons/fa';
import { FaCircleCheck } from 'react-icons/fa6';
import { LuMousePointerClick } from 'react-icons/lu';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppContext_ } from '../../AppContextProvider';
import { ReactComponent as LightningBoltIcon } from '../../icons/lightning-bolt.svg';
import { ReactComponent as TrophyIcon } from '../../icons/trophy.svg';
import { ReactComponent as TruckIcon } from '../../icons/truck.svg';
import useBookmark from '../apiHooks/useBookmark';
import useComments from '../apiHooks/useComments';
import useProfileImage from '../apiHooks/useProfileImage';
import { storeIcon } from '../constants/StoreConstants';
import Share from '../dealDetails/Share';
import CustomDialog from './CustomDialog';

function Post({ item, orientation, type, disableBorder }) {
  const { selectedPosts, setSelectedPosts } = useAppContext_();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    title,
    price,
    prev_price,
    img_link,
    free_shipping,
    vote,
    down_vote,
    updatedAt,
    poster_name,
    id,
    poster_id,
    promotional_code,
    dealer_type,
    isLightningDeal,
    isTopDeal,
  } = item;

  const { bookmark, handleBookmark } = useBookmark(item.id);
  const { profileImage } = useProfileImage(poster_id);
  const { comments } = useComments(item.id);

  const [expired] = useState(isExpired(item));
  const [share, setShare] = useState(false);
  const [promoClicked, setPromoClicked] = useState(false);

  /*
  useEffect(async( ) => {
    let profileImage = useProfileImage(item.poster_id);
    setProfileImage(profileImage);
  }, [item.poster_id]);
  */

  const onClick = (event) => {
    console.log(event.target.nodeName);
    if (
      event.target.nodeName !== 'svg' &&
      event.target.nodeName !== 'path' &&
      event.target.nodeName !== 'BUTTON' &&
      event.target.nodeName !== 'INPUT'
    ) {
      if (location.pathname.startsWith('/deal-details/')) {
        navigate(`/deal-details/${id}`);
        window.location.reload(true);
      } else {
        navigate(`/deal-details/${id}`);
      }
    }
  };

  const handleSelectPost = () => {
    if (selectedPosts.includes(item.id)) {
      setSelectedPosts((prevState) =>
        prevState.filter((item_) => item_ !== item.id)
      );
    } else {
      setSelectedPosts((prevState) => [...prevState, item.id]);
    }
  };

  const gradient = (item) => {
    if (item.isTopDeal) {
      return 'linear-gradient(90deg, #FF00A1 0%, #0D6EFD 50%, #FF00A1 100%)';
    } else if (item.isLightningDeal) {
      return 'linear-gradient(90deg, #FF0000 0%, #FFCA00 50%, #FF0000 100%)';
    } else {
      return '';
    }
  };

  const borderClasses = (item) => {
    if ((item.isTopDeal || item.isLightningDeal) && !disableBorder) {
      return 'tw-p-[2px]';
    } else {
      return 'tw-border tw-border-surface-border';
    }
  };

  const handleShare = async () => setShare(!share);

  const firstClickPromo = async () => {
    setPromoClicked(true);
    navigator.clipboard.writeText(promotional_code);
  };

  const clickPromo = async () => {
    navigator.clipboard.writeText(promotional_code);
  };

  const DarkToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#4c4c4c',
      color: 'rgb(255 255 255 / 98%)',
    },
  }));

  if (orientation === 'horizontal') {
    return (
      <div
        className={`
        tw-animate-shine
        tw-overflow-hidden
        tw-bg-[length:200%_200%]
        tw-flex
          tw-rounded-md
          tw-transition-all
          tw-group hover:tw-cursor-pointer hover:tw-shadow-md
          tw-bg-surface-border ${borderClasses(item)}`}
        style={{
          background: gradient(item),
          backgroundSize: '200% 100%',
        }}
        onClick={onClick}
      >
        <div
          className={`tw-w-full tw-bg-surface-raised tw-flex tw-divide-x tw-divide-surface-border ${
            isLightningDeal || isTopDeal ? 'tw-rounded-[4px]' : ''
          }`}
        >
          <div className="tw-h-full tw-flex tw-justify-center tw-relative tw-p-1 md:tw-p-6 tw-w-[120px] sm:tw-w-[220px] tw-aspect-square">
            <img
              src={img_link}
              className={`tw-flex-1 tw-object-contain ${
                expired ? 'tw-opacity-50' : 'tw-opacity-100'
              }`}
              alt={title}
            />
            <span
              className={`tw-absolute tw-top-3 tw-left-2 ${
                expired ? 'tw-opacity-50' : 'tw-opacity-100'
              }`}
            >
              {!!prev_price && (
                <span className="tw-bg-surface-secondary tw-px-2 tw-pt-1 tw-pb-[2px] tw-rounded-sm tw-text-white tw-text-xs">
                  {`-${percentOff(price, prev_price)}%`}
                </span>
              )}
            </span>

            <span className="tw-absolute tw-top-2 tw-left-2 tw-z-50">
              {isLightningDeal && expired && (
                <span
                  className={`tw-flex tw-gap-x-2 tw-bg-white tw-text-string-secondary tw-px-2 tw-pt-1 tw-pb-[2px]
                    tw-rounded-lg tw-text-xl tw-font-semibold
                    tw-justify-center tw-items-center tw-border-2 tw-border-surface-secondary`}
                >
                  <img src={'/expiredRed.svg'} className="tw-h-6 tw-w-6" />
                  EXPIRED
                </span>
              )}
            </span>
            {(isTopDeal || isLightningDeal) && (
              <span
                className={`tw-flex tw-gap-x-1 tw-items-center tw-absolute tw-left-0 tw-bottom-0 tw-p-1 tw-pl-2 tw-text-[10px] md:tw-text-xs tw-font-semibold tw-rounded-tr-md tw-bg-[length:200%_200%] tw-transition-all tw-animate-shine tw-text-white ${
                  expired ? 'tw-opacity-50' : 'tw-opacity-100'
                }`}
                style={{
                  background: gradient(item),
                  backgroundSize: '200% 100%',
                }}
              >
                {isTopDeal ? (
                  <span className="tw-flex tw-items-center tw-gap-x-1">
                    Top Deal
                    <TrophyIcon className="tw-h-[15px] tw-w-[15px]" />
                  </span>
                ) : (
                  isLightningDeal && (
                    <span className="tw-flex tw-items-center tw-gap-x-1">
                      {expired ? 'Lightning Deal (Expired)' : 'Lightning Deal'}
                      <LightningBoltIcon className="tw-h-[15px] tw-w-[15px]" />
                    </span>
                  )
                )}
              </span>
            )}
          </div>
          <div
            className={`tw-relative tw-w-0 !tw-pl-4 md:!tw-pl-6 tw-px-1 tw-py-2 md:tw-px-[10px] 2xl:tw-px-3 tw-flex tw-flex-col tw-gap-y-2 tw-flex-1 ${
              expired ? 'tw-opacity-50' : 'tw-opacity-100'
            }`}
          >
            <div
              className={`tw-flex tw-text-[10px] md:tw-text-[11px] tw-leading-3 tw-items-center tw-gap-x-1 tw-font-semibold tw-text-string-neutral ${
                expired ? 'tw-opacity-50' : 'tw-opacity-100'
              }`}
            >
              <img
                src={profileImage}
                className="tw-h-5 tw-w-5 tw-rounded-full"
              />
              {poster_name} • {daysDifference(updatedAt)}
            </div>
            <div className="tw-flex tw-flex-col tw-gap-y-1">
              <div>
                <DarkToolTip
                  PopperProps={{
                    sx: {
                      popper: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: '#0000',
                        },
                      },
                    },
                  }}
                  title={title}
                >
                  <div className="tw-pr-12 tw-text-string-base tw-text-sm 2xl:tw-text-base tw-font-bold group-hover:tw-underline group-hover:tw-underline-offset-1 tw-truncate">
                    {title}
                  </div>
                </DarkToolTip>
                <div className="tw-flex tw-items-center tw-gap-x-2">
                  <span className="tw-text-lg tw-text-string-base md:tw-text-xl 2xl:tw-text-2xl tw-font-bold group-hover:tw-underline">
                    ${pricePeriod(price)}
                  </span>
                  {!!prev_price && (
                    <span className="tw-text-string-neutral tw-text-sm 2xl:tw-text-base tw-line-through tw-text-gray-500">
                      ${pricePeriod(prev_price)}
                    </span>
                  )}
                </div>
              </div>
              <div className="tw-flex tw-w-full tw-items-center tw-text-string-neutral">
                <div className="tw-flex tw-gap-x-6">
                  <span className="tw-flex tw-items-center tw-gap-x-1">
                    {vote - down_vote < 0 ? (
                      <ThumbDownOutlinedIcon fontSize="small" />
                    ) : (
                      <ThumbUpOutlinedIcon fontSize="small" />
                    )}
                    <span className="tw-text-xs md:tw-text-sm">
                      {Math.abs(vote - down_vote)}
                    </span>
                  </span>

                  <span className="tw-flex tw-items-center tw-gap-x-1">
                    <MessageOutlinedIcon fontSize="small" />
                    <span className="tw-text-xs md:tw-text-sm">
                      {comments.length}
                    </span>
                  </span>
                </div>
              </div>
              <div className="tw-flex tw-pt-1 tw-gap-x-3">
                <div className="tw-flex tw-items-center tw-gap-x-1 tw-text-string-neutral">
                  {dealer_type in storeIcon && (
                    <img
                      src={storeIcon[dealer_type]}
                      alt={dealer_type}
                      className="tw-object-contain tw-rounded-full tw-w-6 tw-h-6"
                    />
                  )}
                  <span className="tw-text-[10px] md:tw-text-xs 2xl:tw-text-sm tw-whitespace-nowrap">
                    {dealer_type}
                  </span>
                </div>
                {free_shipping && (
                  <div className="tw-flex tw-items-center tw-gap-x-1 tw-text-string-neutral">
                    <div className="tw-relative">
                      <TruckIcon className="tw-text-string-neutral tw-h-[20px] tw-w-[20px]" />
                      <div className="tw-absolute -tw-bottom-[1px] -tw-right-1">
                        <CheckCircleIcon className="!tw-rounded-full !tw-bg-white !tw-fill-green-700 !tw-w-[10px] !tw-h-[10px]" />
                      </div>
                    </div>
                    <span className="tw-text-[10px] md:tw-text-xs 2xl:tw-text-sm tw-whitespace-nowrap">
                      Free Delivery
                    </span>
                  </div>
                )}
                {(free_shipping === false || !!promotional_code) && (
                  <div className="tw-flex tw-gap-x-3">
                    {free_shipping === false && (
                      <div className=" tw-flex tw-flex-1 tw-items-center tw-gap-x-1 tw-text-string-neutral">
                        <div className="tw-relative">
                          <TruckIcon className="tw-text-string-neutral tw-h-[20px] tw-w-[20px]" />
                          <div className="tw-absolute -tw-bottom-[1px] -tw-right-1">
                            <CheckCircleIcon className="!tw-rounded-full !tw-bg-white !tw-fill-green-700 !tw-w-[10px] !tw-h-[10px]" />
                          </div>
                        </div>
                        <span className="tw-text-[10px] md:tw-text-xs 2xl:tw-text-sm tw-whitespace-nowrap">
                          Paid Delivery
                        </span>
                      </div>
                    )}
                    {!!promotional_code && (
                      <div className="tw-flex tw-justify-end md:tw-justify-start tw-items-center tw-gap-x-1 tw-text-string-neutral">
                        <div className="tw-relative">
                          <BiSolidCoupon size={20} className="md:tw-" />
                          <div className="tw-absolute -tw-bottom-[1px] -tw-right-1">
                            <CheckCircleIcon className="!tw-rounded-full !tw-bg-white !tw-fill-green-700 !tw-w-[10px] !tw-h-[10px]" />
                          </div>
                        </div>
                        <span className="tw-text-[10px] md:tw-text-xs 2xl:tw-text-sm tw-whitespace-nowrap">
                          Promo
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <p className="tw-hidden sm:tw-flex mb-0">
              {changeDescription(item)}
            </p>
            <div className="tw-flex tw-gap-x-10">
              <div className="tw-flex tw-gap-x-4">
                <Button
                  LinkComponent={Link}
                  to={`/deal-details/${id}`}
                  variant="contained"
                  color="primary"
                  sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
                  endIcon={<KeyboardArrowRightRoundedIcon />}
                >
                  See Deal
                </Button>
                <div className="tw-flex tw-gap-x-6">
                  <ToggleButton
                    size="small"
                    className="!tw-bg-surface-base !tw-text-string-primary"
                    onClick={handleShare}
                  >
                    <FaRegShareSquare size={20} />
                  </ToggleButton>
                  <Checkbox
                    checked={selectedPosts.includes(item.id)}
                    onChange={handleSelectPost}
                  />
                </div>
              </div>
              {promotional_code && (
                <div className="tw-hidden sm:tw-flex tw-gap-4 tw-flex-wrap sm:tw-flex-nowrap">
                  <div className="tw-flex tw-gap-x-1 tw-items-center">
                    <BiSolidCoupon
                      size={25}
                      className="!tw-text-string-primary"
                    />
                    <span className="tw-text-string-base tw-whitespace-nowrap">
                      Promo Code
                    </span>
                  </div>
                  <TextField
                    className="!tw-hidden lg:!tw-block !tw-w-[90px]"
                    placeholder={promotional_code}
                    InputProps={{
                      readOnly: true,
                    }}
                    size="small"
                    inputProps={{
                      onClick: () => {
                        firstClickPromo();
                      },
                    }}
                  />
                  <Button
                    variant="outlined"
                    className="tw-whitespace-nowrap !tw-hidden lg:!tw-flex"
                    onClick={firstClickPromo}
                    startIcon={
                      promoClicked ? (
                        <FaCircleCheck
                          style={{ color: 'rgba(46, 125, 50, 1)' }}
                        />
                      ) : (
                        <LuMousePointerClick className="!tw-text-string-primary" />
                      )
                    }
                  >
                    {promoClicked ? 'Copied' : 'Click to copy'}
                  </Button>
                </div>
              )}
            </div>
            <span
              className={`tw-absolute tw-top-3 tw-right-3 sm:tw-right-6 ${
                expired ? 'tw-opacity-50' : 'tw-opacity-100'
              }`}
            >
              <div>
                <ToggleButton
                  size="small"
                  value="left"
                  aria-label="left aligned"
                  className="!tw-bg-surface-base"
                  onClick={handleBookmark}
                >
                  {bookmark == null ? (
                    <BookmarkBorderOutlinedIcon
                      fontSize="small"
                      color="primary"
                    />
                  ) : (
                    <BookmarkIcon fontSize="small" color="primary" />
                  )}
                </ToggleButton>
              </div>
            </span>
          </div>
        </div>
        <CustomDialog
          open={share}
          onClose={handleShare}
          title={'Share Deal'}
          description={
            'Share this exciting deal with your friends, family, and community.'
          }
          content={
            <Share
              deal_link={
                item.affiliate_link ? item.affiliate_link : item.deal_link
              }
            />
          }
        />
      </div>
    );
  } else if (orientation === 'vertical') {
    return (
      <div
        className={`tw-overflow-hidden tw-aspect-[9/16] lg:tw-aspect-[0.72/1]
          tw-flex tw-flex-col
          tw-rounded-md tw-bg-[length:200%_200%] tw-transition-all tw-animate-shine
          tw-group hover:tw-cursor-pointer hover:tw-shadow-md
          tw-bg-surface-border ${borderClasses(item)}`}
        style={{
          background: gradient(item),
          backgroundSize: '200% 100%',
        }}
        onClick={onClick}
      >
        <div
          className={`tw-flex-1 tw-h-full tw-bg-surface-raised tw-flex tw-flex-col tw-divide-y tw-divide-surface-border ${
            isLightningDeal || isTopDeal ? 'tw-rounded-[4px]' : ''
          }`}
        >
          <div
            className={`tw-flex tw-text-[10px] md:tw-text-[11px] tw-leading-3 tw-items-center tw-pl-2 tw-pr-2 md:tw-pr-1 tw-py-[5px] tw-gap-x-1 tw-font-semibold tw-text-string-neutral ${
              expired ? 'tw-opacity-50' : 'tw-opacity-100'
            }`}
          >
            <p className="tw-m-0 tw-flex tw-justify-between tw-items-center tw-w-full">
              <div className="tw-flex tw-gap-x-1 tw-items-center">
                <img
                  src={profileImage}
                  className="tw-h-5 tw-w-5 tw-rounded-full"
                />
                <span className="tw-truncate">{poster_name}</span>
              </div>
              <span className="tw-whitespace-nowrap tw-font-normal">
                {daysDifference(updatedAt)}
              </span>
            </p>
          </div>
          <div className="tw-flex tw-flex-1 tw-justify-center tw-relative tw-p-1 md:tw-p-2 tw-h-[60%] 2xl:tw-h-[55%]">
            <img
              src={img_link}
              className={`tw-aspect-square tw-flex-1 tw-object-contain ${
                expired ? 'tw-opacity-50' : 'tw-opacity-100'
              }`}
              alt={title}
            />
            <span
              className={`tw-absolute tw-top-3 tw-left-2 ${
                expired ? 'tw-opacity-50' : 'tw-opacity-100'
              }`}
            >
              {!!prev_price && (
                <span className="tw-bg-surface-secondary tw-px-2 tw-pt-1 tw-pb-[2px] tw-rounded-sm tw-text-white tw-text-xs">
                  {`-${percentOff(price, prev_price)}%`}
                </span>
              )}
            </span>

            <span className="tw-absolute tw-top-2 tw-left-2 tw-z-50">
              {isLightningDeal && expired && (
                <span
                  className={`tw-flex tw-gap-x-2 tw-bg-white tw-text-string-secondary tw-px-2 tw-pt-1 tw-pb-[2px]
                    tw-rounded-lg tw-text-xl tw-font-semibold
                    tw-justify-center tw-items-center tw-border-2 tw-border-surface-secondary`}
                >
                  <img src={'/expiredRed.svg'} className="tw-h-6 tw-w-6" />
                  EXPIRED
                </span>
              )}
            </span>

            <span
              className={`tw-absolute tw-top-2 tw-right-2 ${
                expired ? 'tw-opacity-50' : 'tw-opacity-100'
              }`}
            >
              <div>
                <ToggleButton
                  size="small"
                  value="left"
                  aria-label="left aligned"
                  className="!tw-bg-surface-base"
                  onClick={handleBookmark}
                >
                  {bookmark == null ? (
                    <BookmarkBorderOutlinedIcon
                      fontSize="small"
                      color="primary"
                    />
                  ) : (
                    <BookmarkIcon fontSize="small" color="primary" />
                  )}
                </ToggleButton>
              </div>
            </span>
            {(isTopDeal || isLightningDeal) && (
              <span
                className={`tw-flex tw-gap-x-1 tw-items-center tw-absolute tw-left-0 tw-bottom-0 tw-p-1 tw-pl-2 tw-text-[10px] md:tw-text-xs tw-font-semibold tw-rounded-tr-md tw-bg-[length:200%_200%] tw-transition-all tw-animate-shine tw-text-white ${
                  expired ? 'tw-opacity-50' : 'tw-opacity-100'
                }`}
                style={{
                  background: gradient(item),
                  backgroundSize: '200% 100%',
                }}
              >
                {isTopDeal ? (
                  <span className="tw-flex tw-items-center tw-gap-x-1">
                    Top Deal
                    <TrophyIcon className="tw-h-[15px] tw-w-[15px]" />
                  </span>
                ) : (
                  isLightningDeal && (
                    <span className="tw-flex tw-items-center tw-gap-x-1">
                      {expired ? 'Lightning Deal (Expired)' : 'Lightning Deal'}
                      <LightningBoltIcon className="tw-h-[15px] tw-w-[15px]" />
                    </span>
                  )
                )}
              </span>
            )}
            <span
              className={`tw-absolute tw-bottom-1 tw-right-2 tw-text-[10px] md:tw-text-xs tw-flex tw-flex-col tw-items-end ${
                expired ? 'tw-opacity-50' : 'tw-opacity-100'
              }`}
            >
              {dealer_type in storeIcon && (
                <div className="tw-h-8 tw-w-8 md:tw-h-11 md:tw-w-11 tw-rounded-full tw-overflow-hidden">
                  <img
                    src={storeIcon[dealer_type]}
                    alt={dealer_type}
                    className="tw-object-contain"
                  />
                </div>
              )}
              <div className="tw-font-semibold tw-bg-slate-50 tw-rounded-md tw-py-[1px] tw-px-[2px]">
                {dealer_type && dealer_type.length > 15
                  ? dealer_type.slice(0, 15) + '...'
                  : dealer_type}
              </div>
            </span>
          </div>
          <div
            className={`tw-px-2 tw-pt-1 tw-p-1 md:tw-px-[10px] 2xl:tw-px-3 tw-flex tw-flex-col tw-justify-between tw-flex-1 ${
              expired ? 'tw-opacity-50' : 'tw-opacity-100'
            }`}
          >
            <div className="tw-flex tw-flex-col">
              <div className="tw-flex tw-items-center tw-gap-x-2">
                <span className="tw-text-lg tw-text-string-base md:tw-text-xl 2xl:tw-text-2xl tw-font-bold group-hover:tw-underline">
                  ${pricePeriod(price)}
                </span>
                {!!prev_price && (
                  <span className="tw-text-string-neutral tw-text-sm 2xl:tw-text-base tw-line-through tw-text-gray-500">
                    ${pricePeriod(prev_price)}
                  </span>
                )}
              </div>
              <DarkToolTip
                PopperProps={{
                  sx: {
                    popper: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: '#0000',
                      },
                    },
                  },
                }}
                title={title}
              >
                <div className="tw-text-string-base tw-text-sm 2xl:tw-text-base tw-font-bold group-hover:tw-underline group-hover:tw-underline-offset-1 tw-truncate">
                  {title}
                </div>
              </DarkToolTip>
              <div className="tw-flex tw-flex-1 tw-pt-1 tw-gap-x-3">
                {free_shipping && (
                  <div className="tw-w-full tw-flex tw-flex-1 tw-items-center tw-gap-x-1 tw-text-string-neutral">
                    <div className="tw-relative">
                      <TruckIcon className="tw-text-string-neutral tw-h-[20px] tw-w-[20px]" />
                      <div className="tw-absolute -tw-bottom-[1px] -tw-right-1">
                        <CheckCircleIcon className="!tw-rounded-full !tw-bg-white !tw-fill-green-700 !tw-w-[10px] !tw-h-[10px]" />
                      </div>
                    </div>
                    <span className="tw-text-[10px] md:tw-text-xs 2xl:tw-text-sm tw-whitespace-nowrap">
                      Free Delivery
                    </span>
                  </div>
                )}
                {(free_shipping === false || !!promotional_code) && (
                  <div className="tw-flex tw-flex-1 tw-gap-x-3">
                    {free_shipping === false && (
                      <div className=" tw-flex tw-flex-1 tw-items-center tw-gap-x-1 tw-text-string-neutral">
                        <div className="tw-relative">
                          <TruckIcon className="tw-text-string-neutral tw-h-[20px] tw-w-[20px]" />
                          <div className="tw-absolute -tw-bottom-[1px] -tw-right-1">
                            <CheckCircleIcon className="!tw-rounded-full !tw-bg-white !tw-fill-green-700 !tw-w-[10px] !tw-h-[10px]" />
                          </div>
                        </div>
                        <span className="tw-text-[10px] md:tw-text-xs 2xl:tw-text-sm tw-whitespace-nowrap">
                          Paid Delivery
                        </span>
                      </div>
                    )}
                    {!!promotional_code && (
                      <div className="tw-flex tw-flex-1 tw-justify-end md:tw-justify-start tw-items-center tw-gap-x-1 tw-text-string-neutral">
                        <div className="tw-relative">
                          <BiSolidCoupon size={20} className="md:tw-" />
                          <div className="tw-absolute -tw-bottom-[1px] -tw-right-1">
                            <CheckCircleIcon className="!tw-rounded-full !tw-bg-white !tw-fill-green-700 !tw-w-[10px] !tw-h-[10px]" />
                          </div>
                        </div>
                        <span className="tw-text-[10px] md:tw-text-xs 2xl:tw-text-sm tw-whitespace-nowrap">
                          Promo
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="tw-flex-1 tw-flex tw-items-end">
              <div className="tw-flex tw-w-full tw-justify-between tw-items-center tw-text-string-neutral">
                <div className="tw-flex tw-gap-x-6">
                  <span className="tw-flex tw-items-center tw-gap-x-1">
                    {vote - down_vote < 0 ? (
                      <ThumbDownOutlinedIcon fontSize="small" />
                    ) : (
                      <ThumbUpOutlinedIcon fontSize="small" />
                    )}
                    <span className="tw-text-xs md:tw-text-sm">
                      {Math.abs(vote - down_vote)}
                    </span>
                  </span>

                  <span className="tw-flex tw-items-center tw-gap-x-1">
                    <MessageOutlinedIcon fontSize="small" />
                    <span className="tw-text-xs md:tw-text-sm">
                      {comments.length}
                    </span>
                  </span>
                </div>
                <div className="tw-flex tw-gap-x-6">
                  <IconButton fontSize="small" onClick={handleShare}>
                    <FaRegShareSquare
                      size={20}
                      className="tw-text-string-primary"
                    />
                  </IconButton>
                  <Checkbox
                    checked={selectedPosts.includes(item.id)}
                    onChange={handleSelectPost}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomDialog
          open={share}
          onClose={handleShare}
          title={'Share Deal'}
          description={
            'Share this exciting deal with your friends, family, and community.'
          }
          content={
            <Share
              deal_link={
                item.affiliate_link ? item.affiliate_link : item.deal_link
              }
            />
          }
        />
      </div>
    );
  }
}

function isExpired(item) {
  if (item?.isLightningDeal === 'true') {
    let expired_status = hoursDifference(item.updatedAt);
    if (expired_status === 'expired') {
      return true;
    }
  }

  return false;
}

function pricePeriod(price) {
  if (!price) {
    return 0;
  } else if (price % 1 === 0) {
    return price;
  } else {
    return price.toFixed(2);
  }
}

function percentOff(price, prevPrice) {
  return Math.floor(((prevPrice - price) / prevPrice) * 100);
}

function daysDifference(date) {
  const past = new Date(date);
  const now = new Date();

  const seconds = Math.floor((now - past) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (seconds < 60) {
    return `${seconds} second${seconds === 1 ? '' : 's'} ago`;
  } else if (minutes < 60) {
    return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
  } else if (hours < 24) {
    return `${hours} hour${hours === 1 ? '' : 's'} ago`;
  } else if (days < 7) {
    return `${days} day${days === 1 ? '' : 's'} ago`;
  } else if (weeks < 4) {
    return `${weeks} week${weeks === 1 ? '' : 's'} ago`;
  } else if (months < 12) {
    return `${months} month${months === 1 ? '' : 's'} ago`;
  } else {
    return `${years} year${years === 1 ? '' : 's'} ago`;
  }
}

function hoursDifference(date) {
  const past = new Date(date);
  const now = new Date();

  const seconds = Math.floor((now - past) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (hours >= 0 && hours < 3) {
    return `${hours} hour${hours === 1 ? '' : 's'} ago`;
  } else {
    return 'expired';
  }
}

function changeDescription(item) {
  if (item.description) {
    if (item.description.length >= 180) {
      return item.description;
    } else {
      return item.description;
    }
  } else {
    if (item.instore && item.instore == true) {
      return 'Visit the store for more info';
    } else {
      return 'No details have been provided by the poster. Please check the link for more info.';
    }
  }
}

export default Post;
