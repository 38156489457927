import { Auth } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useLocalStorage from './utils/useLocalStorage';

const AppContext = createContext(null);

const useAppContext_ = () => useContext(AppContext);

function AppContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [theme, setTheme_] = useLocalStorage('theme', 'light');
  const [loading, setLoading] = useState(true);
  const [
    globalNotificationListenerEnabled,
    setGlobalNotificationListenerEnabled,
  ] = useState(() => {
    const savedValue = localStorage.getItem(
      'globalNotificationListenerEnabled'
    );
    return savedValue !== null ? JSON.parse(savedValue) : true;
  });
  const [searchHistory, setSearchHistory] = useState(() => {
    let searchHistory = JSON.parse(localStorage.getItem('searchHistory')) || [];
    // Filter out null items
    searchHistory = searchHistory.filter((item) => item);
    return searchHistory;
  });
  const [pathHistory, setPathHistory] = useState({
    previous: '/',
    current: '/',
  });
  const location = useLocation();

  const preferredUsername =
    user?.attributes?.preferred_username || user?.username;

  const [selectedPosts, setSelectedPosts] = useState([]);

  const getUser = async () => {
    try {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      setUser(authenticatedUser);
    } catch (error) {
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  const setTheme = () => {
    setTheme_((prevState) => {
      let turnTo = '';
      if (prevState === 'light') {
        turnTo = 'dark';
      } else {
        turnTo = 'light';
      }
      document.body.classList.remove(prevState);
      document.body.classList.remove('tw-' + prevState);
      document.body.classList.add(turnTo);
      document.body.classList.add('tw-' + turnTo);
      return turnTo;
    });
  };

  const [HomeCategoryValue, setHomeCategoryValue] = useState();

  const handleHomeCategoryChange = (event, newValue) => {
    setHomeCategoryValue(newValue);
    // console.log(newValue);
    //navigate("/categoryForum", { state: { name: newValue } });
  };

  useEffect(() => {
    const asyncFunction = async () => await getUser();

    document.body.classList.add(theme);
    document.body.classList.add('tw-' + theme);

    asyncFunction();
  }, []);

  useEffect(() => {
    localStorage.setItem(
      'globalNotificationListenerEnabled',
      JSON.stringify(globalNotificationListenerEnabled)
    );
  }, [globalNotificationListenerEnabled]);

  useEffect(() => {
    localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
  }, [searchHistory]);

  useEffect(() => {
    setPathHistory((prevState) => ({
      previous: prevState.current,
      current: location.pathname + location.search,
    }));
  }, [location]);

  /*
   ** Push current userId and username into datalayer to add to Google Analytics
   ** Tracking
   */
  useEffect(() => {
    //console.log(user?.username);
    //console.log(user?.attributes.sub);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      userId: user?.attributes.sub,
      username: user?.username,
      user_id: user?.attributes.sub,
    });
  }, [user]);

  return (
    <AppContext.Provider
      value={{
        user,
        theme,
        setUser,
        setTheme,
        getUser,
        HomeCategoryValue,
        setHomeCategoryValue,
        globalNotificationListenerEnabled,
        setGlobalNotificationListenerEnabled,
        searchHistory,
        setSearchHistory,
        pathHistory,
        preferredUsername,
        selectedPosts,
        setSelectedPosts,
      }}
    >
      {!loading && children}
    </AppContext.Provider>
  );
}

export { AppContext, AppContextProvider, useAppContext_ };
