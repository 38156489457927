import { API } from 'aws-amplify';
import { postDeal } from '../../graphql/mutations';
import { getAutoFilling } from '../../graphql/queries';
import { AUTH_MODE } from '../constants/AppSyncAuthMode';

export async function autoFillingHandler(dealLink) {
  try {
    const response = await API.graphql({
      query: getAutoFilling,
      variables: {
        linkUrl: dealLink,
      },
      authMode: AUTH_MODE.USER_POOLS,
    });
    // console.log('response: ', response);
    return response.data.getAutoFilling;
  } catch (error) {
    console.error('Error: ', error);
    return '';
  }
}

export const deleteDeal = async (dealDetails) => {
  let validDealDetails = {
    createNew: false,
    id: dealDetails.id,
    deleteDeal: true,
  };
  try {
    const response = await API.graphql({
      query: postDeal,
      variables: { input: validDealDetails },
      authMode: AUTH_MODE.USER_POOLS,
    });
    console.log('PostDealResponse:', response);
    const parsedResponse = JSON.parse(response.data.postDeal); // Parse the body field separately

    console.log('id:', parsedResponse.id);
    console.log('message:', parsedResponse.message);
    console.log('status:', parsedResponse.status);

    if (parsedResponse.status === '200') {
      return '';
    } else {
      return parsedResponse.message;
    }
  } catch (error) {
    console.error('Error deleting deal: ', error);
    alert('Error deleting deal: ', error);
    return '';
  }
};

export const deleteDealById = async (id) => {
  let validDealDetails = {
    createNew: false,
    id: id,
    deleteDeal: true,
  };
  try {
    const response = await API.graphql({
      query: postDeal,
      variables: { input: validDealDetails },
      authMode: AUTH_MODE.USER_POOLS,
    });
    console.log('PostDealResponse:', response);
    const parsedResponse = JSON.parse(response.data.postDeal); // Parse the body field separately

    console.log('id:', parsedResponse.id);
    console.log('message:', parsedResponse.message);
    console.log('status:', parsedResponse.status);

    if (parsedResponse.status === '200') {
      return '';
    } else {
      return parsedResponse.message;
    }
  } catch (error) {
    console.error('Error deleting deal: ', error);
    alert('Error deleting deal: ', error);
    return '';
  }
};

export const deleteDealsByIds = async (ids) => {
  await Promise.all(ids.map((id) => deleteDealById(id)));
};

export const dealUpdate = async (dealDetails) => {
  let validDealDetails = {
    createNew: false,
    id: dealDetails.id,
    title: dealDetails.title,
    additionalTitles: dealDetails.additionalTitles,
    description: dealDetails.description,
    deal_link: dealDetails.deal_link,
    additionalLinks: dealDetails.additionalLinks,
    img_link:
      dealDetails.uploaded_img_links &&
      dealDetails.uploaded_img_links.length > 0
        ? dealDetails.uploaded_img_links[0]
        : null,
    has_promotional_code: Boolean(dealDetails.promotional_code),
    promotional_code: dealDetails.promotional_code,
    has_amazon_subscribe_save: Boolean(dealDetails.amazon_subscribe_save),
    amazon_subscribe_save: dealDetails.amazon_subscribe_save,
    has_coupon: Boolean(dealDetails.coupon),
    coupon: dealDetails.coupon,
    expiration_date: dealDetails.expiration_date,
    forum_type: dealDetails.forum_type,
    sub_category: dealDetails.sub_category,
    dealer_type: dealDetails.dealer_type,
    price: parseFloat(dealDetails.price),
    expired: dealDetails.expired,
    prev_price: parseFloat(dealDetails.prev_price),
    uploaded_img_links: dealDetails.uploaded_img_links,
    free_shipping: dealDetails.free_shipping,
    free_pickup: dealDetails.free_pickup,
    instore: dealDetails.instore,
    specific_states: dealDetails.specific_states,
    available_states: dealDetails.available_states,
    specific_stores: dealDetails.specific_stores,
    available_store_addresses: dealDetails.available_store_addresses,
    available_store_zipcodes: dealDetails.available_store_zipcodes,
    available_store_geohashes: dealDetails.available_store_geohashes,
    available_store_placeID: dealDetails.available_store_placeID,
    socials_template: dealDetails.socials_template,
    socials_delay: dealDetails.socials_delay,
    socials_accounts: dealDetails.socials_accounts,
    socials_twitter_content: dealDetails.socials_twitter_content,
    socials_threads_content: dealDetails.socials_threads_content,
    socials_discord_content: dealDetails.socials_discord_content,
    dealType: dealDetails.dealType,
    isTopDeal: dealDetails.isTopDeal,
    isTrendingDeal: dealDetails.isTrendingDeal,
    isLightningDeal: dealDetails.isLightningDeal,
    _version: dealDetails._version,
  };

  try {
    const response = await API.graphql({
      query: postDeal,
      variables: { id: dealDetails.id, input: validDealDetails },
      authMode: AUTH_MODE.USER_POOLS,
    });

    console.log('PostDealResponse:', response.data.postDeal);
    const parsedResponse = JSON.parse(response.data.postDeal); // Parse the body field separately

    console.log('id:', parsedResponse.id);
    console.log('message:', parsedResponse.message);
    console.log('status:', parsedResponse.status);

    if (parsedResponse.status === '200') {
      return ['', parsedResponse.id];
    } else {
      return [parsedResponse.message, ''];
    }
  } catch (error) {
    console.error('Error saving deal settings [0001010]', error);
    console.error(validDealDetails);
    return [error, ''];
  }
};

export const dealCreate = async (dealDetails) => {
  let validDealDetails = {
    createNew: true,
    id: 'id',
    title: dealDetails.title,
    additionalTitles: dealDetails.additionalTitles,
    description: dealDetails.description,
    deal_link: dealDetails.deal_link,
    additionalLinks: dealDetails.additionalLinks,
    // affiliate_link: await fetchAffiliateLink(
    //   dealDetails.tagged_link || dealDetails.deal_link,
    //   dealDetails.title
    // ),
    img_link:
      dealDetails.uploaded_img_links &&
      dealDetails.uploaded_img_links.length > 0
        ? dealDetails.uploaded_img_links[0]
        : null,
    has_promotional_code: Boolean(dealDetails.promotional_code),
    promotional_code: dealDetails.promotional_code,
    has_amazon_subscribe_save: Boolean(dealDetails.amazon_subscribe_save),
    amazon_subscribe_save: dealDetails.amazon_subscribe_save,
    has_coupon: Boolean(dealDetails.coupon),
    coupon: dealDetails.coupon,
    expiration_date: dealDetails.expiration_date,
    forum_type: dealDetails.forum_type,
    sub_category: dealDetails.sub_category,
    dealer_type: dealDetails.dealer_type,
    price: parseFloat(dealDetails.price),
    expired: dealDetails.expired,
    prev_price: parseFloat(dealDetails.prev_price),
    uploaded_img_links: dealDetails.uploaded_img_links,
    free_shipping: dealDetails.free_shipping,
    free_pickup: dealDetails.free_pickup,
    // updated_date: generateCurrentDate(),
    // posted_date: dealDetails.posted_date,
    instore: dealDetails.instore,
    specific_states: dealDetails.specific_states,
    available_states: dealDetails.available_states,
    specific_stores: dealDetails.specific_stores,
    available_store_addresses: dealDetails.available_store_addresses,
    available_store_zipcodes: dealDetails.available_store_zipcodes,
    available_store_geohashes: dealDetails.available_store_geohashes,
    available_store_placeID: dealDetails.available_store_placeID,
    socials_template: dealDetails.socials_template,
    socials_delay: dealDetails.socials_delay,
    socials_accounts: dealDetails.socials_accounts,
    socials_twitter_content: dealDetails.socials_twitter_content,
    socials_threads_content: dealDetails.socials_threads_content,
    socials_discord_content: dealDetails.socials_discord_content,
    dealType: dealDetails.dealType,
    isTopDeal: dealDetails.isTopDeal,
    isTrendingDeal: dealDetails.isTrendingDeal,
    isLightningDeal: dealDetails.isLightningDeal,
    _version: 1,
  };

  try {
    // await deleteAllInStoreDealGeoHashByDealId(dealDetails.id);
    // console.log('Deleted old data in InStoreDealGeoHash Table!');

    const response = await API.graphql({
      query: postDeal,
      variables: { input: validDealDetails },
      authMode: AUTH_MODE.USER_POOLS,
    });

    //console.log('PostDealResponse:', response);
    const parsedResponse = JSON.parse(response.data.postDeal); // Parse the body field separately

    //console.log('id:', parsedResponse.id);
    //console.log('message:', parsedResponse.message);
    //console.log('status:', parsedResponse.status);

    if (parsedResponse.status === '200') {
      return ['', parsedResponse.id];
    } else {
      return [parsedResponse.message, ''];
    }
  } catch (error) {
    console.error('Error saving deal settings  [0001011]', error);
    console.error(validDealDetails);
    return [error, ''];
  }
};

const generateCurrentDate = () => {
  const date = new Date();
  return date.toISOString().split('T')[0];
};

export const dealInit = (isInstore) => {
  // Initialize an empty deal here
  return {
    createNew: true,
    title: '',
    additionalTitles: [],
    description: '',
    price_drop: 0,
    deal_link: null,
    additionalLinks: [],
    has_promotional_code: Boolean(false),
    promotional_code: null,
    has_amazon_subscribe_save: Boolean(false),
    amazon_subscribe_save: null,
    has_coupon: Boolean(false),
    coupon: null,
    expiration_date: null,
    poster_name: null,
    forum_type: null,
    sub_category: null,
    dealer_type: null,
    price: null,
    expired: false,
    poster_img_url: null,
    prev_price: null,
    uploaded_img_links: [],
    free_shipping: false,
    free_pickup: false,
    specific_states: isInstore,
    available_states: isInstore ? ['ALL'] : [],
    specific_stores: false,
    available_store_addresses: [],
    available_store_zipcodes: [],
    available_store_geohashes: [],
    available_store_placeID: [],
    instore: isInstore,
    posted_date: generateCurrentDate(),
    socials_template: '',
    socials_delay: '',
    socials_accounts: [],
    socials_twitter_content: '',
    socials_threads_content: '',
    socials_discord_content: '',
    dealType: 'online',
    isTopDeal: '',
    isTrendingDeal: '',
    isLightningDeal: '',
  };
};
