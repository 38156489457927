import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppContext_ } from '../../AppContextProvider';
import { postLoginApi, signInWithGoogle } from '../../api/account';
import Section from '../home/Section';
import PasswordTextField from '../utils/PasswordTextField';
import { getUsernameByPreferredName } from '../utils/Username';

function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const { setUser } = useAppContext_();

  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const [formErrors, setFormErrors] = useState({
    username: '',
    password: '',
  });

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm() === true) {
      const { username, password } = formData;
      const realUsername = await getUsernameByPreferredName(username);
      // console.log('realUsername: ', realUsername);

      try {
        setLoading(true);
        if (realUsername) {
          await setUser(await postLoginApi(realUsername, password));
        } else {
          await setUser(await postLoginApi(username, password));
        }
        if (location.state !== null) {
          navigate(location.state.destination);
        } else {
          navigate('/');
        }
      } catch (error) {
        setLoading(false);
        if (error.code === 'UserNotConfirmedException') {
          navigate(`/verify?username=${username}`);
        } else if (error.code === 'UserNotFoundException') {
          setFormErrors((prevState) => ({
            ...prevState,
            username: 'Username not found',
            password: 'Username not found',
          }));
        } else if (error.code === 'NotAuthorizedException') {
          setFormErrors((prevState) => ({
            ...prevState,
            username: 'Username and password combination not found',
            password: 'Username and password combination not found',
          }));
        } else {
          // alert(error.code);
          alert(
            'Woops! Due to network outage authentications are unavailable.'
          );
        }
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [name]: '',
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  const validateForm = () => {
    const errors = {};

    if (formData.username.trim() === '') {
      errors.username = 'Username is required';
    }

    if (formData.password.trim() === '') {
      errors.password = 'Password is required';
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGoogleLogin = async () => {
    try {
      const user = await signInWithGoogle();
      console.log('Logged in with Google:', user);
    } catch (error) {
      console.error('Google Login Error:', error);
    }
  };

  return (
    <Section
      content={
        <div className="tw-w-full tw-bg-transparent tw-p-4 tw-border-none md:tw-w-3/4 lg:tw-w-2/3 xl:tw-w-1/2 sm:tw-border-solid sm:tw-bg-surface-raised sm:tw-p-16 tw-rounded-lg sm:tw-border sm:tw-border-surface-border">
          <div className="tw-flex tw-flex-col tw-gap-y-4 tw-mb-10 tw-text-string-base">
            <div>
              <div className="tw-text-3xl tw-font-semibold">Login</div>
              <div className="tw-text-string-neutral">Stay ahead of saving</div>
            </div>
            <div className="tw-text-center">Sign in / Register with</div>
            <div className="tw-flex tw-justify-center tw-gap-x-4">
              <img
                src="/google_logo.png"
                alt="Login through Google"
                onClick={() => {
                  handleGoogleLogin();
                  // alert("Coming soon. Thank you!");
                }}
                className="tw-transition-all hover:tw-opacity-75 hover:tw-shadow-md hover:tw-cursor-pointer tw-p-4 tw-h-20 tw-w-20 tw-rounded-full tw-bg-[#EFF6FF]"
              />
              {/* <img
                src="/facebook_logo.png"
                alt="Login through Facebook"
                onClick={() => {
                  alert('Coming Soon. Thank you!');
                }}
                className="tw-transition-all hover:tw-opacity-75 hover:tw-shadow-md hover:tw-cursor-pointer tw-p-4 tw-h-20 tw-w-20 tw-rounded-full tw-bg-[#EFF6FF]"
              /> */}
            </div>
            <div className="tw-text-center">or</div>
          </div>

          <div>
            <form className="tw-flex tw-flex-col tw-gap-y-4">
              <TextField
                name="username"
                label="Username"
                type="text"
                value={formData.username}
                onChange={handleChange}
                error={!!formErrors.username}
                helperText={formErrors.username || ' '}
              />
              <PasswordTextField
                name="password"
                label="Password"
                value={formData.password}
                onChange={handleChange}
                error={!!formErrors.password}
                helperText={formErrors.password || ' '}
              />
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                onClick={handleSubmit}
                loading={loading}
                loadingPosition="end"
              >
                Login
              </LoadingButton>
            </form>
            <div className="tw-text-string-primary tw-flex tw-justify-between tw-text-xs xs:tw-text-sm md:tw-text-base">
              <Link to="/register" state={location.state}>
                Don't have an account? Sign up.
              </Link>
              <Link to="/reset-password" state={location.state}>
                Forgot password?
              </Link>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default Login;
